import React from 'react'
import {BsInstagram, BsTwitter, BsYoutube} from 'react-icons/bs'
import {FaTiktok} from 'react-icons/fa'

const HeaderSocials = () => {
  return (
    <div className="header__socials">
        <a href="https://youtube.com/@elmagicobeats" target="_blank"><BsYoutube/></a>
        <a href="https://www.instagram.com/elmagicobeats" target="_blank"><BsInstagram/></a>
        <a href="https://twitter.com/ElMagicoBeats" target="_blank"><BsTwitter/></a>
        <a href="https://www.tiktok.com/@elmagicobeats" target="_blank"><FaTiktok/></a>
    </div>
  )
}

export default HeaderSocials