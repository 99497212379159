import React from 'react'
import './about.css'
import Me from '../../assets/me.jpeg'
import { FaAward } from 'react-icons/fa'
import {FiUsers} from 'react-icons/fi'
import {VscFolderLibrary} from 'react-icons/vsc'

const About = () => {
  return (
    <section id='about'>
      <h5>Get To Know</h5>
      <h2>About Me</h2>

      <div className="container about__container">
        <div className="about__me">
          <div className="about__me-image">
            <img src={Me} alt="" />
          </div>
        </div>

        <div className="about__content">
          <div className="about__cards">
            <article className="about__card">
              <FaAward className='about__icon' />
              <h5>Experience</h5>
              <small>5+ years</small>
            </article>

            <article className="about__card">
              <FiUsers className='about__icon' />
              <h5>Clients</h5>
              <small>300+ Worldwide</small>
            </article>

            <article className="about__card">
              <VscFolderLibrary className='about__icon' />
              <h5>Orders</h5>
              <small>500+ completed</small>
            </article>
          </div>
          
          <p>El Magico is a Songwriter, Producer, Mixing and Mastering Engineer from the heart of Europe.
              Growing up with a passion for making Music, he spent his teen years refining his craft as a music maker before jumping head first into the industry to making a living doing so. 
              To date El Magico has worked with over 300 independent artists and completed over 500 order from freelance sites, establishing himself as a very credible Music Producer in the industry.
            </p>

            <a href="#contact" className='btn btn-primary'>Get In Touch</a>
        </div>
      </div>
    </section>
  )
}

export default About