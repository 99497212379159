import React, { useEffect, useState } from 'react'
import Header from './components/header/Header'
import Store from './components/store/Store'
import Licenses from './components/licenses/Licenses'
import About from './components/about/About'
import Newsletter from './components/newsletter/Newsletter'
import Contact from './components/contact/Contact'
import Footer from './components/footer/Footer'


const App = () => {
  const [newsletterVisible, setNewsletterVisible] = useState(false);
  const showHideClassName = newsletterVisible ? "modal display-block" : "modal display-none";
  
  useEffect(() => {
    const timer = setTimeout(() => {
      setNewsletterVisible(true);
    }, 2000);

    return () => {
      clearTimeout(timer);
    }
  }, [newsletterVisible]);

  const handleClose = () => {
    setNewsletterVisible(false);
  }

  return (
    <>
      {/* <div className={showHideClassName} onClick={handleClose}> */}
        <Header />
        <Store />
        <Licenses />
        <About />
        <Contact />
        <Footer />
        {/* {newsletterVisible && <Newsletter />} */}
      {/* </div> */}
    </>
  )
}

export default App