import React from 'react'
import { BiCheck } from 'react-icons/bi';
import './licenses.css'


const Licenses = () => {
  return (
    <section id='licenses'>
      <h5>What I Offer</h5>
      <h2>Licenses</h2>

      <div className="container services__container">
        <article className='service'>
          <div className="service__head">
            <h3>Basic</h3>
            <h4>$29.95 per track</h4>
          </div>

          <ul className="service__list">
            <li>
              <BiCheck className='service__list-icon' />
              <p>MP3 FILE</p>
            </li>
            <li>
              <BiCheck className='service__list-icon' />
              <p>USE FOR MUSIC RECORDING</p>
            </li>
            <li>
              <BiCheck className='service__list-icon' />
              <p>DISTRIBUTE UP TO 2000 COPIES</p>
            </li>
            <li>
              <BiCheck className='service__list-icon' />
              <p>500K AUDIO STREAMS</p>
            </li>
            <li>
              <BiCheck className='service__list-icon' />
              <p>1 MUSIC VIDEO</p>
            </li>
            <li>
              <BiCheck className='service__list-icon' />
              <p>RADIO BROADCASTING RIGHTS (2 STATIONS)</p>
            </li>
          </ul>
        </article>

        <article className='service'>
          <div className="service__head">
            <h3>Premium</h3>
            <h4>$49.95 per track</h4>
          </div>

          <ul className="service__list">
            <li>
              <BiCheck className='service__list-icon' />
              <p>MP3 FILE</p>
            </li>
            <li>
              <BiCheck className='service__list-icon' />
              <p>WAV FILE</p>
            </li>
            <li>
              <BiCheck className='service__list-icon' />
              <p>USE FOR MUSIC RECORDING</p>
            </li>
            <li>
              <BiCheck className='service__list-icon' />
              <p>DISTRIBUTE UP TO 3000 COPIES</p>
            </li>
            <li>
              <BiCheck className='service__list-icon' />
              <p>500K AUDIO STREAMS</p>
            </li>
            <li>
              <BiCheck className='service__list-icon' />
              <p>1 MUSIC VIDEO</p>
            </li>
            <li>
              <BiCheck className='service__list-icon' />
              <p>RADIO BROADCASTING RIGHTS (2 STATIONS)</p>
            </li>
          </ul>
        </article>

        <article className='service'>
          <div className="service__head">
            <h3>Unlimited</h3>
            <h4>$199 per track</h4>
          </div>

          <ul className="service__list">
            <li>
              <BiCheck className='service__list-icon' />
              <p>MP3 FILE</p>
            </li>
            <li>
              <BiCheck className='service__list-icon' />
              <p>WAV FILE</p>
            </li>
            <li>
              <BiCheck className='service__list-icon' />
              <p>TRACK STEMS</p>
            </li>
            <li>
              <BiCheck className='service__list-icon' />
              <p>USE FOR MUSIC RECORDING</p>
            </li>
            <li>
              <BiCheck className='service__list-icon' />
              <p>DISTRIBUTE UNLIMITED COPIES</p>
            </li>
            <li>
              <BiCheck className='service__list-icon' />
              <p>500K AUDIO STREAMS</p>
            </li>
            <li>
              <BiCheck className='service__list-icon' />
              <p>1 MUSIC VIDEO</p>
            </li>
            <li>
              <BiCheck className='service__list-icon' />
              <p>RADIO BROADCASTING RIGHTS (2 STATIONS)</p>
            </li>
          </ul>
        </article>
      </div>
    </section>
  )
}

export default Licenses