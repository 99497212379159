import React from 'react'
import { BsSearch } from 'react-icons/bs'
import Logo from "../../assets/logo.png"
import Artwork from "../../assets/DigitalDustArtwork.jpg"
import './header.css'
import HeaderSocials from './HeaderSocials'

const Header = () => {
    return (
        <header>
            <div className="container header__container">
                <div className="logo">
                    <img src={Logo} alt="logo" />
                </div>

                {/* <h5>Hello I'm</h5>
                <h1>El Magico</h1>
                <h5 className="text-light">Music Producer | Beat Maker | Audio Engineer</h5> */}

                <div className="artwork">
                    <h4><strong>New MPC Expansion!</strong></h4>
                    <a href="https://bit.ly/43iuPFg" target="_blank"><
                        img src={Artwork} alt="artwork" />
                    </a>
                </div>

                <a href="#store" className="btn btn-primary">Find Your Beats <BsSearch /></a>

                <HeaderSocials />
            </div>
        </header>
    )
}

export default Header