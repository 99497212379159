import React from 'react'
import './store.css'

const Store = () => {
  return (
    <section id='store'>
      <div className="container store__container">
        <iframe src="https://player.beatstars.com/?storeId=135160" width="100%" height="800"></iframe>
      </div>
    </section>
  )
}

export default Store