import React from 'react'
import { BsInstagram, BsTwitter, BsYoutube } from 'react-icons/bs'
import { FaTiktok } from 'react-icons/fa'
import "./footer.css"

const Footer = () => {
    return (
        <footer>
            <a href="#" className='footer__logo'>El Magico Beats</a>

            <ul className="permalinks">
                <li><a href="#">Home</a></li>
                <li><a href="#store">Store</a></li>
                <li><a href="#licenses">Licenses</a></li>
                <li><a href="#about">About</a></li>
                <li><a href="#contact">Contact</a></li>
            </ul>

            <div className="footer__socials">
                <a href="https://youtube.com/@elmagicobeats" target="_blank"><BsYoutube /></a>
                <a href="https://www.instagram.com/elmagicobeats" target="_blank"><BsInstagram /></a>
                <a href="https://twitter.com/ElMagicoBeats" target="_blank"><BsTwitter /></a>
                <a href="https://www.tiktok.com/@elmagicobeats" target="_blank"><FaTiktok /></a>
            </div>

            <div className="footer__copyright">
                <small>&copy; El Magico Beats. All Rights Reserved.</small>
            </div>
        </footer>
    )
}

export default Footer